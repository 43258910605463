<template>
    <Modal>
        <h1>{{ headline }}</h1>
        <Form @submit="submit" :processing="processing">
            <fieldset>

                <Select placeholder="Udførende*" v-model="fields.subcontract" :required="true"
                        ref="subcontract">
                    <option v-for="(subcontract, index) in subcontracts" :key="index">{{ subcontract
                        }}
                    </option>
                    <option>Andet</option>
                </Select>

                <Input v-if="useCustomSubcontract"
                       type="text" placeholder="Entreprise*" v-model="fields.customSubcontract"
                       :required="true" tooltip="Fx. tømrer, VVS, EL mm."/>

                <Input type="text" placeholder="Opgavebeskrivelse*" v-model="fields.description"
                       :required="true" tooltip="Indtast opgavens omfang fx montering af vinduer"/>

                <DateSelector placeholder="Startdato*" v-model="fields.from" :min="from" :max="to"
                              :required="true" :shortFormat="true"
                              tooltip="Hvornår påbegyndes arbejdet?" class="half expand-calendar"/>

                <Input type="number" placeholder="Antal dage*" v-model="fields.days"
                       :required="true" :tooltip="daysTooltip" @keyup="daysInputChange"
                       class="half"/>

            </fieldset>
            <ActionButton v-if="!edit" icon="check" :float="true"
                          :disabled="toManyDays || !subscription.isActive" :tooltip="editTooltip"/>
        </Form>
        <template #footer v-if="edit">
            <ActionButton label="Slet" icon="trash" :small="true" :secondary="true" :red="true"
                          @click="isConfirmRemoveDialogOpen = true"
                          :disabled="!subscription.isActive" :tooltip="deleteTooltip"/>
            <ActionButton label="Gem" icon="edit" :small="true" :secondary="true" @click="submit"
                          :disabled="toManyDays || !subscription.isActive" :tooltip="editTooltip"/>
        </template>

        <Dialog v-if="isConfirmRemoveDialogOpen">
            <template #title>Slet aktivitet</template>
            <template #default>
                <p>Du er ved at slette en aktivitet. Når først en aktivitet er slettet, kan den ikke
                    genskabes.</p>
                <p>Er du sikker på at vil slette den alligevel?</p>
            </template>
            <template #actions>
                <ActionButton icon="trash" label="Slet alligevel" :small="true" :red="true"
                              @click="remove"/>
                <ActionButton icon="signout" label="Fortryd" :small="true" :secondary="true"
                              @click="isConfirmRemoveDialogOpen = false"/>
            </template>
        </Dialog>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import DateSelector from '@/components/inputs/Date.vue';
import Form from '@/components/Form.vue';
import Input from '@/components/inputs/Input.vue';
import { projectsCollection, timelineCollection } from '@/utils/collections';
import methods from '@/utils/methods';
import { onMounted, ref } from 'vue';
import useState from '@/store';
import { useRoute } from 'vue-router';
import Select from '@/components/inputs/Select.vue';
import Dialog from '@/components/Dialog.vue';
import { add, isBefore } from 'date-fns';

export default {
    name: 'Activity',
    components: {
        Modal,
        ActionButton,
        DateSelector,
        Form,
        Input,
        Select,
        Dialog,
    },
    setup() {
        const { project, subscription } = useState();
        const route = useRoute();

        const { id } = route.params;
        const edit = !!id;
        const headline = edit ? 'Rediger aktivitet' : 'Tilføj ny aktivitet';

        const from = ref(new Date());
        const to = ref(new Date());

        onMounted(async () => {
            const doc = await projectsCollection().doc(project.value.id).get();
            from.value = doc.data().from.toDate();
            to.value = doc.data().to.toDate();
        });

        return { from, to, id, headline, edit, subscription };
    },
    data() {
        return {
            fields: {
                subcontract: '',
                customSubcontract: null,
                description: null,
                from: null,
                days: null,
            },
            processing: false,
            isConfirmRemoveDialogOpen: false,
            toManyDays: false,
            subcontracts: [
                'Selvbyg (eget arbejde)',
                'Murer',
                'Tømrer',
                'Maler',
                'VVS',
                'El',
                'Ventilation',
                'Tagdækker',
                'Anlægsgartner',
                'Kloak',
                'Beton',
                'Inventar',
                'Arkitekt',
                'Ingeniør',
                'Myndighed',
                'Bygherre',
            ],
            companies: [],
            isLoadingCompanies: true,
        };
    },
    computed: {
        daysTooltip() {
            return this.toManyDays ? 'Dage overstiger projekt slutdato' : 'Dage der skal afsættes';
        },
        useCustomSubcontract() {
            return !!this.fields.subcontract && !this.subcontracts.includes(this.fields.subcontract);
        },
        editTooltip() {
            return !this.subscription.isActive ? 'Tilknyt et abonnement til din konto, for at gemme aktivitet' : null;
        },
        deleteTooltip() {
            return !this.subscription.isActive ? 'Tilknyt et abonnement til din konto, for at slette aktivitet' : null;
        },
    },
    mixins: [methods],
    async mounted() {
        if (this.edit) this.setupTimelineData();
    },
    methods: {
        checkDays() {
            if (!this.fields.days) return true;

            const to = add(this.fields.from, { days: this.fields.days - 1 });

            /* if (this.fields.days) {
                const daysWithoutWeekend = eachWeekendOfInterval({ start: this.fields.from, end: to });
                to = add(this.fields.from, { days: this.fields.days - 1 });
            } */

            return this.fields.from ? isBefore(to, this.to) : true;
        },
        daysInputChange() {
            this.toManyDays = !this.checkDays();
        },
        submit() {
            if (this.checkDays()) {
                if (this.edit) this.update();
                else this.create();
            } else {
                this.toManyDays = true;
            }
        },
        async create() {
            try {
                this.fields.created = new Date();
                this.fields.to = add(this.fields.from, { days: this.fields.days - 1 });
                this.fields.subcontract = this.useCustomSubcontract ? this.fields.customSubcontract : this.fields.subcontract;
                const data = this.removeNullValues(this.$data.fields);
                await timelineCollection().add(data);
                await this.$router.push({ name: 'timeline' });
            } catch (err) {
                console.log(err);
            }
        },
        async update() {
            try {
                this.fields.to = add(this.fields.from, { days: this.fields.days - 1 });
                this.fields.subcontract = this.useCustomSubcontract ? this.fields.customSubcontract : this.fields.subcontract;
                const data = this.removeNullValues(this.fields);
                await timelineCollection().doc(this.id).update(data);
                await this.$router.push({ name: 'timeline' });
            } catch (err) {
                console.log(err);
            }
        },
        async remove() {
            try {
                await timelineCollection().doc(this.id).delete();
                await this.$router.push({ name: 'timeline' });
            } catch (err) {
                console.log(err);
            }
        },
        async setupTimelineData() {
            const timeline = await timelineCollection().doc(this.id).get();
            if (timeline.exists) {
                const data = timeline.data();
                this.fields.subcontract = this.subcontracts.includes(data.subcontract) ? data.subcontract : 'Andet';
                this.fields.customSubcontract = !this.subcontracts.includes(data.subcontract) ? data.subcontract : null;
                this.fields.description = data.description;
                this.fields.from = data.from.toDate();
                this.fields.days = data.days;
            }
        },
    },
};
</script>

<style scoped lang="less">
h2 {
    font-size: 2.4rem;
    font-weight: var(--semibold-weight);
    margin: 0 0 3.5rem;
}

p {
    margin: 1.3rem 0 0;
}
</style>
